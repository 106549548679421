<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingPayrollEventsList || !headerLoaded')
      .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
      .mt-5
          br
          br
          br
          | Chargement de la liste des variables de paie...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :editSettings='editSettings'  :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked"  @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @actionBegin="actionBegin" @deleteButtonClicked="deleteButtonClicked" @archiveButtonClicked="archiveButtonClicked")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "payrollEvent",
      uid: "payrollEvents",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher une variable de paie",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouvelle variable de paie",
        titleEdit: "Modifier la variable de paie",
        titleField: "label",
      },
      allowGrouping: false,
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: false,
      },
      {
        type: "string",
        field: "color",
        headerText: "Couleur",
        width: 60,
        maxWidth: 60,
        allowFiltering: false,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        template: "colorTemplate",
        visible: true,
        unavailableExport: true,
        edit: { type: "colorpicker" },
      },
      {
        type: "string",
        field: "payCode",
        headerText: "Variable",
        width: 200,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        validationRules: { required: true, minLength: 2 },
      },
      {
        type: "boolean",
        field: "isValued",
        headerText: "Est valorisé",
        width: 200,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        valueAccessor: "boolean",
        editType: "booleanedit",
      },
      {
        type: "string",
        field: "label",
        headerText: "Libellé",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        validationRules: { required: true, minLength: 2 },
        clipMode: "EllipsisWithTooltip",
        template: "stripHtmlTemplate",
      },
    ];
  },
  computed: {
    ...mapGetters([
      "payrollEventsList",
      "isLoadingPayrollEventsList",
      "workspaceSelected",
    ]),
    dataFiltered() {
      return this.$store.getters[this.name + "sList"];
    },
  },
  created() {
    this.getPayrollEvents({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getPayrollEvents",
      "createPayrollEvent",
      "updatePayrollEvent",
      "archivePayrollEvents",
      "deletePayrollEvents",
    ]),
    formatCurrency,
    setColumnsByObject,
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        await this.createPayrollEvent({ payrollEvent: args.data });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
      if (args.requestType === "save" && args.action === "edit") {
        await this.updatePayrollEvent({ payrollEvent: args.data });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
    },
    clickAction() {
      this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
    },
    onRowClicked(event) {
      //this.$refs.overviewgrid.selectRow(event.rowIndex, false)
      // this.$refs.overviewgrid.$refs.overviewgrid.startEdit();
    },
    async archiveButtonClicked(args) {
      await this.archivePayrollEvents(args.rowData.id);
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async deleteButtonClicked(args) {
      await this.deletePayrollEvents({
        payrollEventIds: [args.rowData.id],
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
